.nopadding {
    padding: 0 !important;
    margin: 0 !important;

}

.nopadding-left {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.nopadding-right {
    padding-right: 0 !important;
    margin-right: 0 !important;
}

.spacer {
    height: 50px;
}

.opacity-hidden {
    opacity: 0;
}

.small-spacer {
    height: 25px;
}

.back-button {
    float: right;
}

.padded {
    padding: 15px;
}

.delete-button {
    transition: all 0.3s ease;
    &:hover {
        background-color: red;
        color: white;
    }
}

.activate-button {
    transition: all 0.3s ease;
    float: right;
    &:hover {
        background-color: #00ac00;
        color: white;
    }
}

.edit-button {
    transition: all 0.3s ease;
    &:hover {
        background-color: #5355ff;
        color: white;
    }
}

.checkbox {
    margin-top: 1.7em !important;
}


.primary-color-text {
    color: $gold;

}

.bigger-text {
    font-size: 1.3em;
}

.bold-text {
    font-weight: 400;
}

.left-padded {
    padding-left: 15px;
}


.highlighted-text {
    padding-left: 0.8em;
    margin-bottom: 0.5em;
}


.radio-select {

    margin-top: 20px;
    margin-bottom: 20px;


    @include respond-to(xs) {
        margin-left: 0px;
    }



    ul{
        list-style: none;
        height: 130px;
        width: 100%;
        margin: 0;
        padding: 0;
    }


    ul li{
        color: #000;
        display: block;
        position: relative;
        float: left;
        width: 100%;
        height: 37px;

        @include respond-to(480px) {
            height: 60px;
        }

    }

    &.horizontal {

        ul {
            height: 45px;
        }
        ul li {
            width: initial;
        }
    }

    ul li input[type=radio]{
        position: absolute;
        visibility: hidden;
    }

    ul li label{
        display: block;
        position: relative;
        font-weight: 300;
        font-size: 1.15em;
        padding: 2px 16px 25px 58px;
        margin: 4px auto;
        height: 24px;
        z-index: 9;
        cursor: pointer;
        -webkit-transition: all 0.25s linear;
    }

    ul li:hover label{
        color: #000;
    }

    ul li .check{
        display: block;
        position: absolute;
        border: 5px solid #000;
        border-radius: 100%;
        height: 26px;
        width: 26px;
        top: 6px;
        left: 20px;
        z-index: 5;
        transition: border .25s linear;
        -webkit-transition: border .25s linear;
    }

    ul li:hover .check {
        border: 5px solid #000;
    }

    ul li .check::before {
        display: block;
        position: absolute;
        content: '';
        border-radius: 100%;
        height: 10px;
        width: 10px;
        top: 3px;
        left: 3px;
        margin: auto;
        transition: background 0.25s linear;
        -webkit-transition: background 0.25s linear;
    }

    input[type=radio]:checked ~ .check {
        border: 5px solid #3c8dbc;
    }

    input[type=radio]:checked ~ .check::before{
        background: #3c8dbc;
    }

    input[type=radio]:checked ~ label{
        color: #3c8dbc;
    }
}

.ship-addr-delete {
    cursor: pointer;
}

th.filter select {
    width: 100%;
}

.left-border-stripe {
    border-left-style: solid !important;
    border-left-width: 5px !important;

    transition: border-left-color 0.5s linear;
    -webkit-transition: border-left-color 0.5s linear;
}

.bolder-text {
    font-weight: 600;
}
.align-right {
    text-align: right;
}

/* overlay at start */
.mfp-fade.mfp-bg {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
    opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
    opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
    opacity: 0;

    -webkit-transition: all 0.15s ease-out;
    -moz-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
}

.margin-top {
    margin-top: 15px;
}

.subtitle {
    font-size: 0.8em;
    color: grey;

    &--italic {
        font-style: italic;
    }
}

.price-tabs {

}

.price-input {
    margin-top: 20px;
}

.table-add-button {
    margin-bottom: 15px;
}

.inline-block {
    display: inline-block;
}

.active-advert {
    background-color: rgba(0, 202, 0, 0.53) !important;
}

.hl {
    font-weight: 600;
}