#img-cropper {
    max-width: 100%;
    max-height: 400px;

}


.image-drop-area {
    border: 1px dashed #337ab7;
    border-radius: 6px;
    padding: 11px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.dragging {
    background-color: #44b1ff;


}
.gallery-thumbnail {
    height: 250px;
    margin-top: 20px;
}