#category-tree {
    margin: 0px 20px;
    padding: 10px 0px;

}

.category-inactive {
    color: #a1a1a1 !important;
}

.category-buttons {
    padding-left: 10px;
}