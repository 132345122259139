$light-gold: #e8cfa6;
$gold: #c2aa60;
$lightgreen: #adb82b;
$darkgreen: #80a836;
$red: rgba(213, 0, 0, 1);
$bar_height: 35px;
$center_top_padding: 1px;
$darkgray: #c2aa60;
$gray: #c2aa60;
$lightgray: #e8cfa6;
$red: #c2aa60;