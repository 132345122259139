.order-status-select {
    width: 100% !important;
}

.select-filter {
    width: 100%;
}

.order-user-info {
    padding: 20px;
    .row {
        margin-bottom: 10px;
    }
}


#checkout-item-table {
    margin-top: 20px;
    width: 100%;


    thead {

        th {
            font-weight: 600;
            font-size: 0.9em;
            border-bottom: 2px solid $lightgray;

        }
    }

    tbody {

        font-size: 0.9em;
        &:before {
            content: "-";
            display: block;
            line-height: 0.2em;
            color: transparent;
        }

        td {
            border-bottom: 1px solid $lightgray;
            padding: 3px 0px;
        }

        .form-group {
            margin-bottom: 0px;

            width: 60%;

            .price-input {
                text-align: right;
            }
        }

    }

    .img-td {
        width: 100px;
    }
    .order-item-img {
        max-width: 90px;
        max-height: 50px;
    }



}

#checkout-summary-footer {

    #sum-prices {
        margin-top: 30px;
        margin-bottom: 20px;
        margin-right: 20px;
        float: right;
        .sum-title {
            padding-right: 20px;
            text-align: right;
        }

        .total-price {
            font-weight: 400;
        }
    }
}