.item-list-img {
    max-width: 80px;
}

.item-header-params {
    display: inline;
    float: right;
    .checkbox {
        display: inline;
    }
}

.img-subtitle {
    color: #545454;
    font-size: 0.8em;
    font-style: italic;
    margin-top: 15px;

}

.save-first {
    text-align: center;
    font-weight: 600;
    color: gray;
}

.def-variant {
    background-color: #30ee88 !important;
}